import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";

import Landing from "./Landing";
import Header from "./ui/Header";
import Footer from "./ui/Footer";
import theme from "./ui/Theme";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Mindfulness from "./pages/Mindfulness";
import Postural from "./pages/Postural";
import Pt from "./pages/Pt";

const App = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Container>
            <Header
              value={value}
              setValue={setValue}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
            <Route
              exact
              path="/"
              render={(props) => (
                <Landing
                  {...props}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            />
            <Route
              path="/about"
              render={(props) => (
                <About
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            />
            <Route
              path="/mindfulness"
              render={(props) => (
                <Mindfulness
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            />
            <Route
              path="/pt"
              render={(props) => (
                <Pt
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            />
            <Route
              path="/postural"
              render={(props) => (
                <Postural
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            />
            <Route
              path="/contact"
              render={(props) => (
                <Contact
                  {...props}
                  setValue={setValue}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            />
          </Container>
        </Switch>
        <Footer
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </Router>
    </ThemeProvider>
  );
};

export default App;
