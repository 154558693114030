import { createMuiTheme } from "@material-ui/core/styles";
import { common } from "@material-ui/core/colors";

const crystalBlue = "#679CB4";
const pewterBlue = "#81B6AB";
const mediumSpringBud = "#D4CF97";
const burnishedBrown = "black";
const asparagus = "#83AB7C";
const arcGrey = "black";

export default createMuiTheme({
  palette: {
    common: {
      blue: crystalBlue,
      pewterBlue: pewterBlue,
      yellow: mediumSpringBud,
      brown: burnishedBrown,
      asparagus: asparagus,
      arcGrey: arcGrey,
    },
    primary: {
      main: crystalBlue,
    },
    secondary: {
      main: mediumSpringBud,
    },
  },
  typography: {
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      color: `${arcGrey}`,
      fontSize: "1rem",
    },
    contact: {
      fontFamily: "Raleway",
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1.25rem",
      color: burnishedBrown,
      fontWeight: 300,
    },
    h1: {
      fontFamily: "raleway",
      fontWeight: 100,
      fontSize: "3rem",
      color: `${arcGrey}`,
      lineHeight: 1.5,
      marginBottom: "1rem"
    },
    h2: {
      fontFamily: "raleway",
      fontWeight: 700,
      fontSize: "2.8rem",
      color: `${arcGrey}`,
      lineHeight: 1.5,
    },
    h3: {
      fontFamily: "raleway",
      fontWeight: 400,
      fontSize: "2.5rem",
      color: `${arcGrey}`,
      lineHeight: 1.5,
    },
    h4: {
      fontFamily: "raleway",
      fontSize: "1.5rem",
      // color: `${blue}`,
      fontWeight: 700,
    },
    h5: {
      fontFamily: "raleway",
      fontSize: "1.5rem",
      // color: `${blue}`,
      fontWeight: 300,
      marginBottom: "1rem"
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 700,
      color: `${arcGrey}`,
    },
  },
  overrides: {
    underline: {
      "&:before": {
        borderBottom: `2px solid ${asparagus}`,
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `2px solid ${asparagus}`,
      },
    },
  },
});
