import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pagesContainer: {
    marginTop: "10vh",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
}));

const PageHeader = ({ title, content }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.pagesContainer}>
      <Typography variant="h2">{title}</Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: content }}
        variant="body1"
      ></Typography>
    </div>
  );
};

export default PageHeader;
