import React from "react";

import PageHeader from "../../components/layout/PageHeader";
import Container from "@material-ui/core/Container";

const Pt = () => {
  const title = 'Personal Training'
  const content = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Duis tristique sollicitudin nibh sit amet. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Sagittis vitae et leo duis. Nunc aliquet bibendum enim facilisis gravida neque. Aenean vel elit scelerisque mauris pellentesque. Porta nibh venenatis cras sed. Elementum sagittis vitae et leo. Libero id faucibus nisl tincidunt eget nullam non nisi"

  return (
    <Container>
      <PageHeader title={title} content={content}/>
    </Container>
  );
};

export default Pt;
