import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import footerSlash from "../../assets/footer.svg";
import facebook from "../../assets/facebook.svg";
import Linkedin from "../../assets/Linkedin.svg";
import instagram from "../../assets/instagram.svg";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    marginTop: "10em",
    position: "fixed"
  },
  adornment: {
    width: "5em",
    verticalAlign: "bottom",
    [theme.breakpoints.down("md")]: {
      width: "10em",
    },
    [theme.breakpoints.down("xs")]: {
      width: "10em",
    },
  },
  icon: {
    height: "4em",
    width: "4em",
    [theme.breakpoints.down("xs")]: {
      height: "2.5em",
      width: "2.5em",
    },
  },
  socialContainer: {
    position: "absolute",
    marginTop: "-6em",
    right: "1.5em",
    [theme.breakpoints.down("xs")]: {
      right: "0.6em",
    },
  },
}));

const Footer = ({ routes, setValue, setSelectedIndex }) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <img
        alt="black decorative slash"
        src={footerSlash}
        className={classes.adornment}
      />

      <Grid
        container
        justify="flex-end"
        spacing={2}
        className={classes.socialContainer}
      >
        <Grid
          item
          component={"a"}
          href="#"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="facebook logo" src={facebook} className={classes.icon} />
        </Grid>
        <Grid
          item
          component={"a"}
          href="#"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="twitter logo" src={Linkedin} className={classes.icon} />
        </Grid>
        <Grid
          item
          component={"a"}
          href="#"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="instagram logo" src={instagram} className={classes.icon} />
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
