import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";

import { Grid, Button, Typography } from "@material-ui/core";
import ButtonArrow from "./ui/ButtonArrow";

import pt from "../assets/pt.svg";
import motiveras from "../assets/motiveras.svg";
import postural from "../assets/postural.svg";
import mindfulness from "../assets/Mindfulness.svg";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: "4em",
    align: "center",
    justify: "center",
    alignSelf: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      marginTop: "5em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "5em",
    },
  },
  heroTextContainer: {
    minWidth: "21.5em",
    marginLeft: "1em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  backgroundImage: {
    maxWidth: "40em",
    minWidth: "20em",
    marginTop: "1em",
    marginLeft: "5%",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "30em",
      marginRight: 0,
      marginTop: "1em",
    },
  },
  button: {
    borderRadius: 50,
    height: 45,
    width: 145,
    marginRight: 40,
  },
  readMoreButton: {
    ...theme.typography.contact,
    color: theme.palette.common.mediumSpringBud,
  },
  buttonContainer: {
    marginTop: "1em",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
  },
  contactButton: {
    ...theme.typography.contact,
    backgroundColor: theme.palette.common.asparagus,  
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  subtitle: {
    marginBottom: "1em",
  },
  icon: {
    height: "10em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  servicesSection: {
    marginTop: "2em",
    marginBottom: "5em",
    [theme.breakpoints.down("sm")]: {
      padding: 25,
      marginTop: 0,
    },
  },
  serviceButton: {
    marginTop: "3em",
  },
  serviceImage: {
    height: "3em",
  },
  serviceImage2: {
    height: "10em",
  },
  card: {
    width: "100%",
  },
  root: {
    flexGrow: 1,
  },
  coop: {
    marginTop: "5em",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    alignItems: "center",
    maxWidth: "80%",
    minWidth: "50%",
    justifyContent: "center",
    color: theme.palette.text.secondary,
  },
  servicesHeading: {
    marginTop: "15em",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  box: {
    marginTop: "3em",
  },
}));

const Landing = () => {

  const classes = useStyles();
  const theme = useTheme();
 
  return (
    <Container direction="column" className={classes.mainContainer}>
      <Grid item>
        <Grid container justify="center" alignItems="center" direction="row">
          <Grid sm item className={classes.heroTextContainer}>
            <Typography variant="h1" align="center">
              Personal Website
            </Typography>
            <Typography variant="h5" align="center">
              Welcome to my website!
            </Typography>
            <Grid
              container
              justify="center"
              className={classes.buttonContainer}
            >
              <Grid item>
                <Button
                  component={Link}
                  to="/contact"
                  className={classes.contactButton, classes.button}
                  variant="contained"
                >
                  Get in touch!
                </Button>
              </Grid>
              
            </Grid>
          </Grid>{" "}
          <Grid sm item className={classes.backgroundImage}>
            <img src={motiveras} alt="motiveras" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.toScrollHidden} id="services"></Grid>
      <Grid item className={classes.servicesHeading}>
        <Typography variant="h3" align="center">
          Services
        </Typography>
      </Grid>

      <Grid
        container
        spacing={6}
        justify="center"
        align="center"
        direction="row"
        className={classes.servicesSection}
      >
        <Grid item xs={12} md={4}>
          <Grid container direction="column">
            <Grid item className={classes.card}>
              <img src={pt} alt="personlig träning" className={classes.icon} />
              <Typography variant="h4">Personal Traing</Typography>
              <Button
                component={Link}
                to="/pt"
                variant="outlined"
                className={classes.serviceButton}
              >
                <span style={{ marginRight: 10 }}>Read more...</span>
                <ButtonArrow
                  width={10}
                  height={10}
                  fill={theme.palette.common.blue}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container direction="column" justify="center">
            <Grid item className={classes.card}>
              <img
                src={mindfulness}
                alt="Mindfulness"
                className={classes.icon}
              />
              <Typography variant="h4">Mindfulness</Typography>
              <Button
                component={Link}
                to="/mindfulness"
                variant="outlined"
                className={classes.serviceButton}
              >
                <span style={{ marginRight: 10 }}>Read more...</span>
                <ButtonArrow
                  width={10}
                  height={10}
                  fill={theme.palette.common.blue}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container direction="column">
            <Grid item className={classes.card}>
              <img src={postural} alt="postural" className={classes.icon} />
              <Typography variant="h4">Postural Training</Typography>
              <Button
                component={Link}
                to="/postural"
                variant="outlined"
                className={classes.serviceButton}
              >
                <span style={{ marginRight: 10 }}>Read more...</span>
                <ButtonArrow
                  width={10}
                  height={10}
                  fill={theme.palette.common.blue}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


    </Container>
  );
};
export default Landing;
